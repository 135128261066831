<template>
  <div id="newsDetail">
    <MediumContainer>
      <div v-if="errorPage" class="errorBox">
        データがありません。
      </div>
      <div class="title">
        <span v-html="newsDetail.title.rendered"></span>
      </div>
      <div class="date">
        {{ displayDate }}
      </div>

      <div v-if="newsDetail.imagesGallery.length !== 0" class="magazineBox">
        <div
          v-for="(item, idx) in newsDetail.imagesGallery"
          :key="idx"
          class="magazineItem"
        >
          <img class="magazinImage" :src="item.img" :alt="item.text" />
          <p class="magazineText">
            {{ item.text }}
          </p>
        </div>
      </div>
      <div v-if="newsDetail.youtubeUrl !== ''" class="videoWrapper">
        <iframe
          :src="newsDetail.youtubeUrl"
          :width="video.width"
          :height="video.height"
          frameborder="0"
        ></iframe>
      </div>
      <div class="body" v-html="sanitizedContentText"></div>
    </MediumContainer>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import sanitizeHtml from "sanitize-html";
import config from "../constants/config";
import { mapActions } from "vuex";
const endpoint = config.ENDPOINT;
export default {
  name: "NewsDetail",
  components: {},
  data() {
    return {
      pageId: "",
      errorPage: false,
      newsDetail: {
        id: "",
        modified: "",
        title: {
          rendered: ""
        },
        content: {
          rendered: ""
        },
        //以下二つは独自で定義
        youtubeUrl: "",
        imagesGallery: []
        // myCustomField: {
        //   "youtube-embed-url:": "",
        //   "image-gallery": []
        // }
      },
      video: {
        width: "600px",
        height: "400px"
      }
    };
  },
  computed: {
    sanitizedContentText() {
      return sanitizeHtml(this.newsDetail.content.rendered);
    },
    displayDate() {
      const dateStr = this.newsDetail.modified;
      if (dateStr === "" || dateStr === undefined) return "";
      return dayjs(dateStr).format("YYYY/MM/DD H:m");
    }
  },
  created() {
    this.set();
  },
  methods: {
    ...mapActions({
      showLoading: "Utility/showLoading",
      hideLoading: "Utility/hideLoading"
    }),
    set() {
      this.pageId = this.$route.params.news_id;
      this.requestSinglePost();
    },
    async requestSinglePost() {
      this.showLoading();
      const url = `${endpoint}single.php`;
      const result = await axios.get(url, {
        params: {
          pid: this.pageId
        }
      });
      this.errorPage = false;
      this.hideLoading();
      if (result.data.error !== undefined) {
        console.log("エラー 表示なし");
        this.errorPage = true;
        return;
      }
      // sanitizeする
      result.data.title.rendered = sanitizeHtml(result.data.title.rendered);
      result.data.content.rendered = sanitizeHtml(result.data.content.rendered);
      Object.keys(this.newsDetail).forEach(key => {
        if (key === "youtubeUrl") {
          this.newsDetail.youtubeUrl = this.convertYoutubeEmbedUrl(
            result.data.myCustomField["youtube-embed-url"]
          );
        } else if (key === "imagesGallery") {
          this.newsDetail.imagesGallery =
            result.data.myCustomField["image-gallery"];
        } else {
          this.newsDetail[key] = result.data[key];
        }
      });
    },
    convertYoutubeEmbedUrl(url) {
      return url.replace("watch?v=", "embed/");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
#newsDetail {
  margin: setSingleMargin(2) setSingleMargin(1);
  .title {
    margin-top: setSingleMargin(1);
    margin-bottom: setSingleMargin(0.5);
    font-size: 4rem;
    font-weight: 600;
    @include lessThan(md) {
      font-size: 3rem;
    }
    @include lessThan(sm) {
      font-size: 2rem;
    }
  }
  .date {
    font-size: 0.8rem;
  }
  .magazineBox {
    margin: setSingleMargin(1) auto 0 auto;
    .magazineItem {
      margin: setSingleMargin(3) auto 0 auto;
      text-align: left;
      .magazinImage {
        // max-width: 500px;
        @include lessThan(md) {
          max-width: 100%;
        }
        @include lessThan(sm) {
          max-width: 100%;
        }
      }
      .magazineText {
        font-size: 0.85rem;
        white-space: pre-wrap;
        margin: setSingleMargin(0.5) auto 0 auto;
      }
    }
  }
  // .swiperBox {
  //   margin: setSingleMargin(1) auto 0 auto;
  // }
  .videoWrapper {
    margin: setSingleMargin(1) auto 0 auto;
  }
  .body {
    border-top: 1px solid $border-color;
    white-space: pre-wrap;
    line-height: 7px;
    margin-top: setSingleMargin(3);
    padding-top: setSingleMargin(1);
    text-align: left;
    font-size: 0.85rem;
  }
}
</style>
